<template>
  <div class="card card-body">
    <form @submit.prevent="newTeamHandler">
      <div class="input-group">
        <input v-model="newTeamForm.name" type="text" class="form-control" placeholder="Enter new team name"
               aria-label="Recipient's username" aria-describedby="basic-addon2" required>
        <div class="input-group-append">
          <button class="btn btn-dark" type="submit">Submit</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'NewEventTeam',
  props: ['eventId'],

  data() {
    return {
      newTeamForm: {},
    };
  },

  methods: {
    newTeamHandler() {
      const payload = {
        name: this.newTeamForm.name,
        event_id: this.eventId,
      }

      this.$store.dispatch('event/newTeam', payload).then(() => {
        this.newTeamForm.name = null;
      });
    },
  }
}
</script>