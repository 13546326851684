<template>
  <div>
    <!-- file input -->
    <div class="row mb-4">
      <div class="col">
        <input
          type="file"
          ref="file"
          @change="onChange($event.target.files)"
          accept=".csv"
        />
        <p class="text-danger mt-1" v-show="error">{{ error }}</p>
      </div>
    </div>

    <!-- preview -->
    <div class="row" v-if="headers.length">
      <div class="col">
        <h4>Preview Data</h4>
        <table class="table table-striped table-responsive">
          <thead>
            <tr class="text-uppercase">
              <td v-for="(header, headerIndex) of headers" :key="headerIndex">
                {{ header }}
              </td>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(row, dataIndex) of data"
              :key="dataIndex"
              :class="{ 'table-danger': errors && errors[dataIndex] }"
            >
              <td v-for="(col, colIndex) of row" :key="colIndex">
                {{ col ? col : "-" }}
                <span
                  v-if="errors && errors[dataIndex]"
                  class="text-danger font-weight-bold"
                >
                  <br />
                  {{ getError(errors[dataIndex], colIndex) }}
                </span>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td :colspan="headers.length - 1">
                Participants Count: <b>{{ this.data.length }}</b>
              </td>
              <td class="text-right">
                <button class="btn btn-sm btn-primary" @click="onSubmit">
                  Submit
                </button>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      file: null,
      headers: [],
      data: null,
      error: null,
      validate: true,
      errors: [],
    };
  },

  methods: {
    ...mapActions("event", {
      import: "bulkImportParticipants",
    }),

    process(rows) {
      if (rows.length < 2) {
        this.error = "Please select file with minimum 2 rows.";
        return;
      }

      this.error = null;
      const expectedHeaders = ["email", "weight", "height", "ftp"];
      const headers = this.stripQuotes(rows.splice(0, 1)[0].split(","));

      if (this.validate) {
        const error = `Headers does't match. Expected headers are ${expectedHeaders.join(
          ", "
        )}.`;

        // headers length mismatch error
        if (headers.length != expectedHeaders.length) {
          this.error = error;

          return;
        }

        let isValid = true;

        // match headers with expected headers in the given order
        headers.forEach((h, i) => {
          isValid = isValid && expectedHeaders[i] === h.toLowerCase();
        });

        if (!isValid) {
          // headers mismatch error
          this.error = error;
          return;
        }
      }

      this.headers = headers;
      const data = [];

      rows.forEach((row) => {
        const cols = row.split(",");

        if (cols.length == this.headers.length) {
          data.push(this.stripQuotes(cols));
        }
      });
      this.data = data;
    },

    stripQuotes(cols) {
      return cols.map((c) => {
        c = c.trim();

        if (c.charAt(0) == '"' && c.charAt(c.length - 1) == '"') {
          c = c.substr(1, c.length - 2);
        }

        return c;
      });
    },

    onChange(files) {
      // reset the information
      this.headers = this.data = this.errors = [];

      if (!files.length) {
        return;
      }

      const file = files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        this.process(e.target.result.split("\n"));
      };

      reader.readAsText(file);
    },

    async onSubmit() {
      if (!confirm("Are you sure to upload?")) {
        return;
      }

      try {
        this.errors = [];
        const payload = new FormData();
        payload.append("file", this.$refs.file.files[0]);
        payload.append("event_id", this.$route.params.id);

        await this.import(payload);
        this.data = this.errors = this.headers = [];
        this.$refs.file.value = null;

        this.$emit("uploaded");
      } catch (error) {
        console.log("unable to perform bulk upload.", error);

        if (
          error?.message &&
          (Array.isArray(error.message) || typeof error.message == "object")
        ) {
          this.errors = error.message;
        }
      }
    },

    getError(row, col) {
      // get attribute name from headers by it's position
      const attribute = this.headers?.[col];

      // get error by row index and attribute name
      return row?.[attribute] || null;
    },
  },
};
</script>

<style scoped>
table {
  position: relative;
}

table thead,
table tbody,
table tfoot {
  display: block;
  width: 100%;
}

table tbody {
  max-height: 320px;
  overflow: auto;
}
table thead,
table tfoot {
  font-weight: 700;
}
table thead > tr,
table tbody > tr,
table tfoot > tr {
  display: table;
  table-layout: fixed;
  width: 100%;
}

table tr > td {
  white-space: normal;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: var(--secondary);
}
</style>
