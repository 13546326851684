<template>
  <section>
    <div class="card text-left mb-0">
      <div class="card-header">
        <div class="row align-items-center">
          <div class="col-10">
            <h3 class="mb-0">Participants of {{ event.name }}</h3>
            <small><strong>{{ formateDate(event.datetime) }}</strong></small>
            <br />
            <!--            <small><strong>Friday 22nd of January 2021 at 04:30 PM GST</strong></small> <br>-->
            <small>Distance: {{ event.distance }} KM / Elevation:
              {{ event.elevation }} Meters</small>
            <br />
            <small>Min Cap: {{ event.min_cap }} W/KG / Max Cap:
              {{ event.max_cap }} W/KG</small>
            <br />
          </div>
          <div class="col-2">
            <small>
              <strong>Participants ({{ totalCount }})</strong> <br />
              <span class="text-info">Pending = {{ pendingCount }}</span>, <span class="text-success">Approved = {{
                approvedCount }}</span>, <br />
              <span class="text-warning">Rejected = {{ rejectedCount }}</span>, <span class="text-danger">Removed = {{
                removedCount }}</span>,<br />
              <span>
                <router-link :to="{ name: 'AdminEventsEdit', params: { id: eventId } }"
                  style="text-decoration:underline">View Event Details</router-link>
              </span><br />

              <vue-json-to-csv :json-data="craftCsv(event)" :csv-title="event.name">
                <button class="btn btn-outline-info btn-sm mt-1">
                  <b>Download Participants Data</b>
                </button>
              </vue-json-to-csv>
            </small>
          </div>
        </div>
      </div>
      <div class="card-body p-0">
        <div class="btn-group" role="group" aria-label="Basic example">
          <a class="btn btn-secondary" data-toggle="collapse" href="#newTeam" role="button" aria-expanded="false"
            aria-controls="newTeam">Create New Team</a>
          <a class="btn btn-secondary" data-toggle="collapse" href="#manualParticipant" role="button"
            aria-expanded="false" aria-controls="manualParticipant">Manually Add Participant</a>
          <a
            class="btn btn-secondary"
            data-toggle="collapse"
            href="#bulkUploadParticipants"
            role="button"
            aria-expanded="false"
            aria-controls="bulkUploadParticipants"
          >
            Bulk Upload Participants
          </a>
            <button
              class="btn btn-secondary"
              v-if="selectedParticipants.length > 0"
              data-toggle="modal"
              data-target="#bulkActionModal"
            >
              Bulk Action
            </button>
        </div>

        <div class="collapse" id="newTeam">
          <NewTeam :eventId="eventId" />
        </div>

        <div class="collapse" id="manualParticipant" v-if="totalCount < eventParticipantCount">
          <ManualParticipant :eventId="eventId" :teams="eventTeams" />
        </div>
        <div style="padding:15px;" v-else>
          <p style="font-size:14px;">Participants limit reached.</p>
        </div>
        <div class="collapse" id="bulkUploadParticipants">
          <BulkUploadParticipants class="m-4" @uploaded="status = 'pending'" />
        </div>
      </div>
    </div>

    <!-- Bulk Action Modal -->
    <div
      class="modal fade"
      id="bulkActionModal"
      tabindex="-1"
      aria-labelledby="bulkActionModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
            <h5 class="modal-title" id="bulkActionModalLabel">Bulk Action</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <!-- Modal Body -->
          <div class="modal-body pt-0">
            <UpdateModal :status="status" :applicants="selectedParticipants" @updated="onBulkActionComplete" />
          </div>
        </div>
      </div>
    </div>

    <div class="row my-3 p-2">
      <div class="col-2 offset-8">
        <select v-model="category" class="custom-select" id="category">
          <option value="0">All</option>
          <option value="1">CAT-1</option>
          <option value="2">CAT-2</option>
          <option value="3">CAT-3</option>
          <option value="4">CAT-4</option>
        </select>
      </div>
      <div class="col-2">
        <select v-model="status" class="custom-select" id="sort">
          <option value="approved">Approved</option>
          <option value="pending">Pending</option>
          <option value="declined">Declined</option>
        </select>
      </div>
    </div>
    <div class="card" style="overflow: auto;">
      <table class="styled-table">
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                :checked="selectedParticipants.length > 0"
                @click="selectParticipants($event.target.checked)"
              />
            </th>
            <th>Source</th>
            <th>Type</th>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Gender</th>
            <th>Team Name</th>
            <th>Jersey</th>
            <th>Status</th>
            <th>Application Date</th>
            <th>Category</th>
            <th v-if="event.CategorizationRuleType == 'ftp'">FTP</th>

            <th v-if="event.CategorizationRuleType == 'power'">6 sec</th>
            <th v-if="event.CategorizationRuleType == 'power'">30 sec</th>
            <th v-if="event.CategorizationRuleType == 'power'">3 min</th>
            <th v-if="event.CategorizationRuleType == 'power'">12 min</th>

            <th v-if="event.CategorizationRuleType == 'rider_history'">5 sec</th>
            <th v-if="event.CategorizationRuleType == 'rider_history'">30 sec</th>
            <th v-if="event.CategorizationRuleType == 'rider_history'">1 min</th>
            <th v-if="event.CategorizationRuleType == 'rider_history'">2 min</th>
            <th v-if="event.CategorizationRuleType == 'rider_history'">5 min</th>
            <th v-if="event.CategorizationRuleType == 'rider_history'">20 min</th>
            <th v-if="event.CategorizationRuleType == 'rider_history'">60 min</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>

          <tr v-for="(participant, i) in getParticipants" :key="i"
            :class="{ 'text-primary': participant.source === 'admin', 'no_category' : !(participant.categoryName)   }" >
            <td>


              <input
                type="checkbox"
                :checked="participant.selected"
                @click="
                  selectParticipant({
                    selected: $event.target.checked,
                    index: i,
                  })
                "
              />
            </td>

            <td>{{ participant.source || "web" }}</td>
            <td>{{ participant.team === "y" ? "Team" : "Individual" }}</td>
            <td >
<!--              <span class="new_label" > New </span>-->
              <span class="new_label"  v-if="participant.user_event_automation
            && participant.user_event_automation.isNewUser" > New </span>

              {{ participant.firstName }} {{ participant.lastName }}

            </td>
            <td>{{ participant.email }}</td>
            <td v-if="participant.phone">
              {{ `${participant.code} ${participant.phone}` }}
            </td>
            <td v-else>
              none
            </td>
            <td>{{ getGender(participant.gender) }}</td>
            <td>{{ participant.teamName || "Null" }}</td>
            <td>{{ participant.jerseyName || "Null" }}</td>
            <td class="text-uppercase" :class="statusClass(participant.common.status)">
              {{ participant.common.status }}
            </td>
            <td>{{ formateDate(participant.common.created_at) }}</td>
            <td>
              {{ participant?.categoryName ?? '--' }}
            </td>

            <td v-if="event.CategorizationRuleType == 'ftp' ">

              {{ participant?.user_event_automation?.categorization?.Ftp ??  '--' }}

            </td>


            <td v-if="event.CategorizationRuleType == 'rider_history'" >

             {{ participant?.user_event_automation?.categorization?.PowerDistribution?.[0]?.values?.power ??  '--' }}
            </td>
            <td v-if="event.CategorizationRuleType == 'rider_history'" >
              {{ participant?.user_event_automation?.categorization?.PowerDistribution?.[1]?.values?.power ??  '--' }}
            </td>
            <td v-if="event.CategorizationRuleType == 'rider_history'" >
              {{ participant?.user_event_automation?.categorization?.PowerDistribution?.[2]?.values?.power ??  '--' }}
            </td>
            <td v-if="event.CategorizationRuleType == 'rider_history'" >
              {{ participant?.user_event_automation?.categorization?.PowerDistribution?.[3]?.values?.power ??  '--' }}
            </td>
            <td v-if="event.CategorizationRuleType == 'rider_history'" >
              {{ participant?.user_event_automation?.categorization?.PowerDistribution?.[4]?.values?.power ??  '--' }}
            </td>

            <td v-if="event.CategorizationRuleType == 'rider_history'" >
              {{ participant?.user_event_automation?.categorization?.PowerDistribution?.[5]?.values?.power ??  '--' }}
            </td>
            <td v-if="event.CategorizationRuleType == 'rider_history'" >
              {{ participant?.user_event_automation?.categorization?.PowerDistribution?.[6]?.values?.power ??  '--' }}
            </td>


            <td  v-if="event.CategorizationRuleType == 'power'">

              {{ participant?.user_event_automation?.categorization?.PowerPassport?.[0]?.values?.power ?? '--' }}

            </td>

            <td  v-if="event.CategorizationRuleType == 'power'">

              {{ participant?.user_event_automation?.categorization?.PowerPassport?.[1]?.values?.power ?? '--' }}

            </td>

            <td  v-if="event.CategorizationRuleType == 'power'">
              {{ participant?.user_event_automation?.categorization?.PowerPassport?.[2]?.values?.power ?? '--' }}
            </td>
            <td  v-if="event.CategorizationRuleType == 'power'">

              {{ participant?.user_event_automation?.categorization?.PowerPassport?.[3]?.values?.power ?? '--' }}

            </td>

            <td>
              <router-link :to="{
                name: 'participant-details',
                params: {
                  id: eventId,
                  participantId: participant.common._id,
                },
              }">View</router-link>
            </td>
          </tr>
          <tr v-if="!getParticipants.length">
            <td colspan="11">No participants found in {{ this.status }} state.</td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>
<script>



import { mapGetters, mapMutations, mapActions } from "vuex";
import * as dayjs from "dayjs";
import Swal from "sweetalert2";
import VueJsonToCsv from 'vue-json-to-csv';


import NewTeam from "@/components/event/newTeam";
import ManualParticipant from "@/components/event/ManualParticipant";
import UpdateModal from "./UpdateModal.vue";
import BulkUploadParticipants from './BulkUploadParticipants.vue';

export default {
  name: "AdminEventsParticipants",

  components: {  NewTeam, ManualParticipant, VueJsonToCsv, UpdateModal, BulkUploadParticipants },

  data() {
    return {
      eventId: this.$route.params.id,
      declineReason: null,
      declineId: null,
      updateTeamForm: {},
      status: "approved",
      category: 0,
      selectedValue: '',
      options: [
        { value: '0', text: '--' },
        { value: '1', text: 'CAT-1' },
        { value: '2', text: 'CAT-2' },
        { value: '3', text: 'CAT-3' },
        { value: '4', text: 'CAT-4' }
      ],
      isActive: false
    };
  },

  computed: {
    ...mapGetters("event", [
      "event",
      "eventParticipants",
      "selectedParticipants",
    ]),

    getParticipants() {
      if (!this.eventParticipants) return;
      return this.eventParticipants;
    },

    totalCount() {
      return this.pendingCount + this.approvedCount + this.rejectedCount;
    },

    eventParticipantCount() {
      return this.event.participantsLimit ?? 0;
    },

    pendingCount() {
      if (!this.event || !this.event?.participant_stats) return 0;

      return this.event.participant_stats.pending;
    },

    approvedCount() {
      if (!this.event || !this.event?.participant_stats) return 0;

      return this.event.participant_stats.approved;
    },

    rejectedCount() {
      if (!this.event || !this.event?.participant_stats) return 0;

      return this.event.participant_stats.declined;
    },

    removedCount() {
      // commented below lines, as we are deleting the candidates permantely
      // if (!this.getParticipants) return 0;
      // return this.getParticipants.filter((p) => p.common.status === "removed")
      //   .length;
      return 0;
    },

    attachments() {
      if (!this.getParticipants) return 0;
      return this.getParticipants.filter((p) => p.common.status === "removed")
        .length;
    },

    eventTeams() {
      if (!this.event.teams) return;
      const teams = Object.assign([], this.event.teams);
      teams.unshift({ _id: "", Name: "None" });
      return teams;
    },

    eventJerseys() {
      if (!this.event.jerseys) return;
      const jerseys = Object.assign([], this.event.jerseys);
      jerseys.unshift({ _id: 0, Name: "None" });
      return jerseys;
    },
  },

  mounted() {
    this.$store.dispatch("event/getEvent", {
      id: this.eventId,
      status: this.status,
      category: this.category
    });

  },

  methods: {

    ...mapMutations("event", {
      selectParticipant: "SELECT_PARTICIPANT",
      selectParticipants: "SELECT_PARTICIPANTS",
    }),

    ...mapActions("event", ["bulkUpdateParticipantsStatus"]),

    onBulkActionComplete() {
      this.selectParticipants(false);
      window.$('#bulkActionModal').modal('hide');
      this.$store.dispatch("event/getEvent", {
        id: this.eventId,
        status: this.status,
      });
    },

    declineIt(id) {
      this.declineId = id;
    },

    craftCsv(event) {
      let participants = event.participants;
      if (!participants) {
        return [];
      }

      let csvData = [];
      let eventDate = this.formateDate(event.datetime);
      participants.forEach(function (element) {
        csvData.push({
          "Participant Count": participants.length,
          "Event Date Time": eventDate,
          "Event Name": event.name,
          "Event ID": element.serverEventId,
          "User ID": element.userUuid,
          'Team Name': element.team === "y" ? element.teamName : "Individual",
          // "Jersey Name": element.jerseyName || "Null",
          "Name": element.firstName + " " + element.lastName,
          "Contact Number": `${(element.code) || "-"} ${(element.phone) || "-"}`,
          "User Login": element.email,
          "Country": element?.user?.country?.label || "NA",
          "Height": element.height,
          "Weight": element.weight,
          "FTP": element.ftp, 
          "w/kg": parseFloat(element.ftp / element.weight).toFixed(2)
        });
      });
      return csvData;
    },

    getGender(gender) {
      if (parseInt(gender) === 0) {
        return "Male";
      }
      return "Female";
    },

    isAttachment(key, value) {
      if (value) {
        return value.length > 0
          ? value.startsWith("https://mywhooshprod.s3")
          : value;
      } else return value;
    },

    formateDate(value, date = null) {
      if (!value) return;

      value = value.replace("T", " ").replace("Z", "");
      if (!date)
        return value ? dayjs(value).format("DD/MM/YYYY hh:mm A") : null;
      else return value ? dayjs(value).format("DD/MM/YYYY") : null;
    },

    borderClass(value) {
      return {
        "border-success": value === "approved",
        "border-danger": value === "declined",
        "border-danger2": value === "removed",
        "border-info": value === "pending",
      };
    },

    statusClass(value) {
      return {
        "text-success": value === "approved",
        "text-danger": value === "declined",
        "text-danger2": value === "removed",
        "text-info": value === "pending",
      };
    },

    getParticipantTeam(teamId) {
      const team = this.eventTeams.filter((team) => team.TeamId === teamId);
      if (team.length === 0) return "None";
      return team[0].Name;
    },

    getJerseys(jerseyId) {
      const jersey = this.eventJerseys.filter(
        (jersey) => jersey.Id === jerseyId
      );
      if (jersey.length === 0) return "None";
      return jersey[0].Name;
    },

    updateParticipantTeamHandler(participantId) {
      const payload = {
        participantId,
        eventId: this.eventId,
        teamId: this.updateTeamForm.teamId || "",
        teamName: this.eventTeams.filter(
          (team) => team.TeamId === this.updateTeamForm.teamId
        )[0].Name,
        jerseyId: this.updateTeamForm.jerseyId || "",
        jerseyName: this.eventJerseys.filter(
          (jersey) => jersey.Id === this.updateTeamForm.jerseyId
        )[0].Name,
      };

      this.$store.dispatch("event/updateParticipantTeam", payload);
    },

    updateStatus(status, id) {
      const payload = {
        status: status,
        participant_id: id,
        event_id: this.eventId,
      };

      Swal.fire({
        title: "Are you sure?",
        text: `You would like to ${status.toUpperCase()} this participant`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: `Yes, ${status.toUpperCase()} it!`,
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.value) {
          // this.$store.dispatch('event/update_status', payload);
          if (payload.status === "declined" && this.declineReason) {
            payload.decline_reason = this.declineReason;
          }
          this.$store.dispatch("event/update_status", payload);

          const foundIndex = this.getParticipants.findIndex(
            (p) => p.common._id === id
          );
          this.getParticipants[foundIndex].common.status = status;
        }
      });
    },

    async downloadAttachment(url) {
      const res = await this.$store.dispatch("event/downloadAttachment", {
        url,
      });
      if (res.status && res.data.url) {
        window.open(res.data.url);
        return;
      }
      alert(
        "There seems to be a problem with file downloading from server. Please try again!"
      );
    },
  },
  watch: {
    status(value) {
      this.$store.dispatch("event/getEvent", {
        id: this.eventId,
        status: value,
        category: this.category,
      });
    },
    category(value) {
      this.$store.dispatch("event/getEvent", {
        id: this.eventId,
        status: this.status,
        category: value,
      });
    },
  },
};
</script>

<style scoped>
  .no_category{
    background-color: #edd3db !important;
  }
  .new_label{
    color: #ab2828;
    background-color: #5de09e;
    padding: 5%;
  }
label {
  margin-bottom: 0;
}

.card-body p {
  font-size: 12px;
  margin: 0;
  border-bottom: 1px solid #fff;
}

.card-body p label {
  background-color: #f7f7f7;
  padding: 3px 10px;
  min-width: 200px;
  max-width: 200px;
  display: inline-block;
  margin: 0 10px 0 0;
  text-transform: lowercase;
}

.styled-table {
  border-collapse: collapse;
  font-size: 0.9em;
  font-family: sans-serif;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table thead tr {
  background-color: #e0e0e0;
  color: #2d2d2d;
  text-align: left;
}

.styled-table th,
.styled-table td {
  padding: 12px 15px;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #e0e0e0;
}

.styled-table tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}
</style>
