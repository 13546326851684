<template>
  <form @submit.prevent="onSubmit">
    <div class="form-group" v-if="['pending', 'declined'].includes(status)">
      <label>Status</label>
      <select class="custom-select" v-model="action" required>
        <option value="">Select Status</option>
        <option value="approved" v-if="status == 'pending'">Approved</option>
        <option value="declined" v-if="status == 'pending'">Declined</option>
        <option value="removed">Delete</option>
      </select>
    </div>

    <div class="form-group" v-if="action == 'declined' && status != 'approved'">
      <label for="reason">Decline Reason</label>
      <textarea
        v-model="decline_reason"
        class="form-control"
        id="reason"
        rows="3"
        required
      ></textarea>
    </div>
    <template v-if="status === 'approved'">
      <div class="form-group">
        <label>Team</label>
        <select class="custom-select" v-model="team">
          <option
            v-for="(team, t) of teams"
            :key="'team-' + t"
            :value="team.TeamId || ''"
          >
            {{ team.Name }}
          </option>
        </select>
      </div>

      <div class="form-group">
        <label>Jersey</label>
        <select class="custom-select" v-model="jersey" required>
          <option
            v-for="(jersey, j) of jerseys"
            :key="'jersey-' + j"
            :value="jersey.Id || ''"
          >
            {{ jersey.Name }}
          </option>
        </select>
      </div>

      <div class="form-group">
        <label for="category">Category</label>
        <select class="custom-select" v-model="category" required id="category">
          <option
            v-for="(category, k) of categories"
            :key="'category-' + k"
            :value="category.CategoryId"
          >
            {{ category.Label }}
          </option>
        </select>
      </div>
    </template>

    <div class="modal-footer p-0">
      <button type="button" class="btn btn-secondary" data-dismiss="modal">
        Cancel
      </button>
      <button type="submit" class="btn btn-primary">Submit</button>
    </div>
  </form>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  inject: ["dropdowns"],
  
  props: {
    status: {
      required: true,
      type: String,
    },
    applicants: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      // categories,
      action: "",
      category: "",
      jersey: "",
      team: "",
      decline_reason: "",
    };
  },
  computed: {
    ...mapGetters("event", ["event"]),

    teams() {
      if (!this.event.teams) {
        return [];
      }

      const teams = Object.assign([], this.event.teams);
      teams.unshift({ _id: "", Name: "None" });

      return teams;
    },

    jerseys() {
      if (!this.dropdowns.jerseies) return;
      const jerseys = Object.assign([], this.dropdowns.jerseies);
      jerseys.unshift({ Id: 0, Name: "None" });
      return jerseys;
    },

    categories() {
      if (!this.dropdowns.categories) return;
      const categories = Object.assign([], this.dropdowns.categories);
      categories.unshift({ CategoryId: "0", Label: "All" });
      return categories;
    },

    applicant_ids() {
      return this.applicants.map((applicant) => applicant._id);
    },
  },
  methods: {
    ...mapActions("event", {
      updateStatus: "bulkUpdateParticipantsStatus",
      updateTeams: "bulkUpdateParticipantsTeam",
    }),

    async onSubmit() {
      let payload = {};
      let confirmationRequired = true;
      let action = "updateTeams";

      if (this.status === "approved") {
        confirmationRequired = false;
        payload = {
          participantIds: this.applicant_ids,
          teamId: this.team,
          jerseyId: this.jersey,
          categoryId: this.category,
          teamName:
            this.teams.filter((team) => team.TeamId === this.team)[0]?.Name ||
            "None",
          jerseyName: this.jerseys.filter(
            (jersey) => jersey.Id === this.jersey
          )[0]?.Name,
          categoryName: this.categories.filter(
            (cat) => cat.CategoryId === this.category
          )[0]?.Label,
        };
      } else {
        action = "updateStatus";
        payload = {
          status: this.action,
          participant_ids: this.applicant_ids,
        };

        if (this.action === "declined") {
          payload["decline_reason"] = this.decline_reason;
        }
      }

      if (
        !confirmationRequired ||
        confirm("Are you sure to perform this action?")
      ) {
        try {
          const response = await this[action](payload);

          if (response?.status === true) {
            this.$emit("updated");
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
  },

  watch: {
    // reset the action everytime the status has changed
    status() {
      this.action = "";
    },
  },
};
</script>
