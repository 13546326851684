<template>
  <div class="card card-body">
    <form @submit.prevent="newParticipantHandler">
      <div class="row">
        <div class="col-3">
          <div v-if="getError('user_id')" class="text-danger">required</div>
          <autocomplete
              :source="url"
              :requestHeaders="authHeaders"
              v-model="form.user_id"
              results-property="data"
              results-display="email"
              input-class="form-control"
              placeholder="Enter email to search"
          />
        </div>
        <div class="col-2">
          <div v-if="getError('weight')" class="text-danger">required</div>
          <input v-model="form.weight" type="text" class="form-control" placeholder="Participant Weight"/>
        </div>
        <div class="col-3">
          <div v-if="getError('ftp')" class="text-danger">required</div>
          <div class="input-group">
            <input v-model="form.ftp" type="text" class="form-control" placeholder="Participant FTP"
                   aria-label="Participant FTP" aria-describedby="basic-addon2">
            <div class="input-group-append">
              <span class="input-group-text bg-light text-dark" id="basic-addon2">Avg Watt: {{ form.avg_watt }}</span>
            </div>
          </div>
        </div>
        <div class="col-2">
          <div v-if="getError('team_id')" class="text-danger">required</div>
          <div class="input-group">
            <select v-model="form.team_id" class="custom-select" id="inputGroupSelect01">
              <option v-for="(team, t) of teams" :key="t" :value="team.TeamId">
                {{ team.Name }}
              </option>
            </select>
            <div class="input-group-append">
              <label class="input-group-text bg-light text-dark" for="inputGroupSelect01">Team</label>
            </div>
          </div>
        </div>
        <div class="col-1">
          <button class="btn btn-dark" type="submit">Submit</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import Autocomplete from 'vuejs-auto-complete'

export default {
  name: 'NewEventTeam',
  props: ['eventId', 'teams'],

  components: {
    Autocomplete,
  },

  data() {
    return {
      form: {},
      submitted: false,
      url: `${process.env.VUE_APP_API_URL}/players/find?email=`
    }
  },

  validations: {
    form: {
      user_id: {required},
      weight: {required},
      ftp: {required},
      team_id: {},
    }
  },

  computed: {
    authHeaders() {
      const token = localStorage.getItem('adminToken');
      return {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': `Bearer ${token}`,
      };
    },
  },

  watch: {
    'form.ftp': {
      handler: function (val) {
        this.form.avg_watt = 0;
        if (val && this.form.weight) {
          this.form.avg_watt = this.avgWatt(val, this.form.weight)
        }
      }
    },

    'form.weight': {
      handler: function (val) {
        this.form.avg_watt = 0;
        if (val && this.form.ftp) {
          this.form.avg_watt = this.avgWatt(this.form.ftp, val)
        }
      }
    },
  },

  methods: {
    avgWatt(ftp, weight) {
      return Number((ftp / weight).toFixed(2))
    },

    getError(field) {
      if (this.$v.form[field]) {
        return this.submitted && this.$v.form[field].$invalid;
      }
    },

    newParticipantHandler() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) return;

      let teamId = "";
      let teamName = "";
      if(this.form.team_id) {
        teamId = this.form.team_id
        teamName = this.teams.find(team => team.TeamId === this.form.team_id).Name
      }

      this.form.team_id = teamId
      this.form.team_name = teamName
      this.form.event_id = this.eventId

      this.$store.dispatch('event/addManualParticipant', this.form);
    },
  }
}
</script>